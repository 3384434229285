import React from 'react';
import {
    isMobile, isMobileOnly, isTablet,
} from 'react-device-detect';
import { Img } from 'react-image';
import LocalMoonsterPreviewGif from '../../assets/moonster-preview.gif';

const MoonsterPreviewGif = {
    cdn: 'https://storage.googleapis.com/moonster-images/moonster-preview.gif',
    local: LocalMoonsterPreviewGif,
};

const headerMessage = 'Spaceship building in progress';

const bodyMessage = "3,844 uniquely generated NFTs invading Earth with love and kindness  💖  (yeah, that's cute I know). Moonsters don't have fingers to write a roadmap... but bet you a trip to the moon that we've got some groovy stuff planned";

const isMobileOrTablet = () => (isMobile || isTablet);

const MessageAndButtons = ({ isLandscape, isPortrait }) => (
    <div
        style={{
            textAlign: (isMobileOrTablet() && isPortrait) ? 'center' : 'left',
            paddingRight: (isMobileOrTablet()) ? '0%' : '5%',
            width: (isMobile) ? '86%' : '50%',
            marginBottom: (isMobileOrTablet() && isPortrait) ? '10%' : '0%',
        }}
    >
        <div
            className="messageContainer"
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: (isLandscape) ? '10%' : '0%',
            }}
        >
            <div
                style={{
                    fontSize: (isMobileOnly) ? '28px' : '50px',
                }}
            >
                {headerMessage}
            </div>
            <p
                style={{
                    fontSize: (isMobileOnly) ? '16px' : '24px',
                    lineHeight: (isMobileOnly) ? '24px' : '42px',
                }}
            >
                {bodyMessage}
            </p>
        </div>
        <div
            className="discordTweetButtonGroup"
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: (isMobileOrTablet() && isPortrait) ? 'center' : 'normal',
                gap: '25px',
                height: (isMobileOnly) ? '45px' : '75px',
            }}
        >
            <button
                type="button"
                style={{
                    backgroundColor: '#1E90E9',
                    borderRadius: '60px',
                    border: 'none',
                    color: 'white',
                    width: '35%',
                    fontFamily: "'Share Tech Mono', monospace",
                    fontSize: (isMobileOnly) ? '16px' : '28px',
                }}
                onClick={() => window.open('https://twitter.com/MoonstersNFT')}
            >
                Twitter
            </button>
            <button
                type="button"
                onClick={() => window.open('https://discord.gg/tM7kpVqKsh')}
                style={{
                    backgroundColor: '#5865F2',
                    borderRadius: '60px',
                    border: 'none',
                    color: 'white',
                    width: '35%',
                    fontFamily: "'Share Tech Mono', regular",
                    fontSize: (isMobileOnly) ? '16px' : '28px',
                }}
            >
                Discord
            </button>
        </div>
    </div>
);

const PrelaunchMesssage = ({ isLandscape, isPortrait }) => (
    <div
        style={{
            // position: 'relative',
            zIndex: 1,
            display: 'flex',
            flexDirection: (isMobileOrTablet() && !isLandscape) ? 'column' : 'row',
            marginTop: '5%',
            alignItems: 'center',
            position: 'relative',
        }}
    >
        <MessageAndButtons isLandscape={isLandscape} isPortrait={isPortrait} />
        <div
            style={{
                order: !(isMobileOrTablet() && !isLandscape) ? '-1' : 0,
                width: (isMobileOnly) ? '75%' : '50%',
            }}
        >
            <Img
                src={[MoonsterPreviewGif.cdn, MoonsterPreviewGif.local]}
                alt=""
                width="65%"
                style={{
                    borderRadius: '50%',
                    boxShadow: '0px 4px 44px #ffffff',
                }}
            />
        </div>
    </div>
);

export default PrelaunchMesssage;
