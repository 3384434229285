import React from 'react';
import { withOrientationChange } from 'react-device-detect';
import Logo from '../V1/Logo';
import MoonSurface from './MoonSurface';
import PrelaunchMesssage from './PrelaunchMessage';

let PreLaunch = ({ isLandscape, isPortrait }) => (
    <>
        <div className="App">
            <Logo />
            <PrelaunchMesssage isLandscape={isLandscape} isPortrait={isPortrait} />
            <MoonSurface />
        </div>
    </>
);

PreLaunch = withOrientationChange(PreLaunch);

export default PreLaunch;
