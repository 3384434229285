import React from 'react';
import LocalLogoImage from '../../assets/moonster-logo.webp';

const LogoImage = {
  cdn: 'https://storage.googleapis.com/moonster-images/moonster-logo.webp',
  local: LocalLogoImage,
};

const Logo = () => (
  <div className="Logo">
    <img
      src={LogoImage.cdn}
      fallback={LogoImage.local}
      alt=""
      style={{
        objectFit: 'cover',
        overflow: 'hidden',
        marginTop: '10%',
      }}
      height="100%"
      width="100%"

    />
  </div>
);

export default Logo;
