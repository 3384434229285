import React from 'react';
import './App.css';
import PreLaunch from './components/V2/Prelaunch';
import '@fontsource/share-tech-mono'; // Defaults to weight 400.

const App = () => (
  <PreLaunch />
);

export default App;
