import React from 'react';
import { Img } from 'react-image';
import LocalMoonSurface from '../../assets/moon-surface.webp';

const MoonSurfaceAsset = {
    cdn: 'https://storage.googleapis.com/moonster-images/moon-surface.webp',
    local: LocalMoonSurface,
};

const MoonSurface = () => (
    <div className="V2Footer">
        <div className="MoonSurfaceImageWrapper">
            <Img
                className="MoonSurfaceImage"
                src={
                    [
                        MoonSurfaceAsset.cdn,
                        MoonSurfaceAsset.local,
                    ]
                }
                alt=""
            />
        </div>
    </div>
);

export default MoonSurface;
